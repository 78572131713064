<template>
  <div class="setarea-continer">
    <div class="setarea-center">
      <el-table :data="tableData" border style="width: 100%">
        <el-table-column prop="sites" label="不可下单地区" width="180">
        </el-table-column>
        <el-table-column prop="reason" label="不可下单原因"> </el-table-column>
        <el-table-column fixed="right" label="操作" width="300">
          <template slot-scope="scope">
            <el-button @click="handleClick(scope.row)" type="text" size="small"
              >编辑地区</el-button
            >
            <el-button type="text" size="small">编辑原因</el-button>
            <el-button type="text" size="small">删除</el-button>
          </template>
        </el-table-column>
      </el-table>
       <div class="xz"><el-button type="primary">新增</el-button></div>
    </div>
  </div>
</template>

<script>
import { setareaList } from '../../api/setarea';
export default {
  data() {
    return {
      tableData: [],
    };
  },
  methods: {
    getList() {
      setareaList().then((res) => {
        console.log(res);
        if(res.code===10000){
          this.tableData=res.data
        }
      });
    },
  },
  created() {
    this.getList();
  },
};
</script>

<style scoped>
.setarea-center {
  width: 80%;
  margin: auto;
}
.xz{
  margin-top: 30px;
}
</style>
