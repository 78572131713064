import request from '../utils/request'
import baseURL from '../utils/config'

//设置不可发货区域
//得到不可发货区域列表
export function setareaList() {
  return request({
      url: baseURL.baseURL + 'api/Adm_CannotSend/GetList',
      method: 'get',
  })
}